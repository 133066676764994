@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


.image-gallery-content .image-gallery-slide .image-gallery-image 
{
    max-height: calc(75vh - 80px) !important;
}

.fullscreen-modal .image-gallery-content .image-gallery-slide .image-gallery-image 
{
    max-height: calc(100vh - 80px) !important;
}


/* @import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;700;900&family=Noto+Serif:wght@700&display=swap'); */

/* .image-gallery-image {
    height: 40vh !important;
} */

/* .fullscreen .image-gallery-image {
    height: 95vh !important;
} */


/* Remove underline on links */

/* ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  } */


/* overflow-x hidden added to address vw issue in chrome that adds :horizontal image in wordpresspost.js fullimage */



/* .image-gallery-slide .image-gallery-image {
    width: 95%;
} */

.image-gallery-slide .image-gallery-image {
    width: 99% !important;
}

.slick-dots {
    display: flex !important;
    
}

 .slick-slider {

 justify-content: center !important;
 max-width: 2200px;
 margin: auto;
} 

.slick-dots li {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
}

.slick-active li {   
    background: #1a1a1a;
}

.slick-dots li button:before {
    margin-top: 20px;
    background: #000000;
    width: 100%;
    height: 2px;
    content: '' !important;
    opacity: 0.1;
} 
  


.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
   
    margin-bottom: 30px;
  }



/* #root {
    overflow-x: hidden;
} */

.linebreak {
    white-space: pre-wrap;
} 

body {
    overflow: scroll;
}



a:any-link {
    text-decoration: none;
    color: inherit;

}

a:-webkit-any-link {
    text-decoration: none;
    color: inherit;
   ;
  }

.grid-name {
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 2.4em;
    font-family: "Cormorant Garamond", "serif" !important; 
    padding-bottom: 0px !important;
    line-height: 1.2em !important;
    font-weight: 600 !important;
}

.markdown p {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.fullscreen {
    background: white !important;
}


.image-gallery-bullets {
    bottom: -20px !important;
    margin-top: 40px !important;
}


/* .image-gallery-image {

    height:'100px',
} */

@media only screen and (max-width: 960px) {
    .image-gallery-slide .image-gallery-image {
    width: 'auto' !important;
    height: 300px !important;
  }
}


  .image-gallery-bullets .image-gallery-bullet {
    box-shadow: none; 
   background-color: #717171;
   padding: 2px;
   border: none;
  }

  .image-gallery-bullets .image-gallery-bullet.active {
    background: #000000;
    padding: 3px;
    border: none;
  }

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    padding: 4px !important;
}

.image-gallery.fullscreen-modal {
    background: white;
    /* transform: scale(1.5); */
}

.image-gallery.fullscreen-modal .image-gallery-content {
    top: 0px !important;
    transform: none !important;
    text-align: center;
    width: 100%;
}


.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {    
    outline: none;
    border: 4px solid #56472e !important;
    transition: none !important;
    cursor:  pointer !important;
}

.image-gallery-thumbnail {    
    /* background: #eee; */
    border: 4px solid transparent;
}




.fullscreen-modal {
    padding-left: 25px !important;
}

.image-gallery.fullscreen-modal  {
    padding-right: 25px;
}

/* .image-gallery-thumbnails-wrapper.fullscreen-modal  {
    height: 100vh !important;
} */

/* .image-gallery-image {
    padding-left: 25px;
} */

.image-gallery-slide.fullscreen-modal {
    padding-left: 0px !important;
}


/* 
.image-gallery-slide-wrapper.left {
    width: 100% !important;
} */


:focus {
    outline: none !important;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: none !important;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-top: 10px !important;
}

/* .image-gallery.fullscreen-modal {
    background: white !important;
    display: flex;
    align-items: center;
    height: 100%;    
}  */


/* .image-gallery.fullscreen-modal {
    background: white !important;
    display: flex;
    align-items: center;
    height: 100%;    
}  */

/* .image-gallery-slide {
    max-height: 100vh;
} */



.image-gallery-slide {
    max-height: 100vh;
}

 .image-gallery.fullscreen-modal {
    background: white !important;
    display: flex;
    align-items: center;
    /* height:'100%'; */
}  

/* .image-gallery-image { 
max-height: 100vh !important;


}   */

 .fullscreen-modal .image-gallery-image  {
      height: 90vh !important;
      
 }




.fullscreen .image-gallery-right-nav, .fullscreen .image-gallery-left-nav {
    visibility: visible;
    display: block;
}

.image-gallery-right-nav, .image-gallery-left-nav {
    display: none;
    visibility: hidden;
}

.image-gallery-icon {
    color: #212121;
    filter: none !important;
}

.image-gallery-icon:hover {
    color: #212121;
    filter: none !important;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 80px;
    width: 60px;
}

.image-gallery-content {
    text-align: center;
}


.image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    width: calc(100% - 20px);
}


/* .image-gallery-fullscreen-button {
    position: fixed !important;
    top: 0px !important;
    bottom: none !important;
} */

/* .fullscreen .image-gallery-slide .image-gallery-image {
    height: 100vh !important;
    padding-bottom: 5vh;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
} */



/* .fullscreen .image-gallery-index {
    visibility: visible;
    left: 46%;
    background: none;
    color: black;
    font-size: 1.5em;
    top: 90vh;
}

.image-gallery-index {
    visibility: hidden;
 
} */

/* .image-gallery-slide .image-gallery-image  {
    height: 70vh !important;
} */

/* .alignfull img, .wp-block-image.alignfull img {
       margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
    overflow-x:hidden;
    overflow-y:hidden;
} */

.alignfull, .wp-block-image.alignfull {
    /* display: flex; */
    align-items: flex-end;
    justify-content: flex-start;
     margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
/* 
 width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);  */


    
}


.divider
{
    
    position: relative;
    margin-top: 0px;
    height: 1px;
    width: 100%;
    margin: 0 auto;
}

.div-transparent:before
{
    content: "";
    position: absolute;
    top: 0;
    

    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, #bdbdbd, #bdbdbd, #bdbdbd);
}



.div-arrow-down:after
{
    content: "";
    position: absolute;
    z-index: 1;
    top: -11px;
    left: calc(10% - 7px);
    width: 24px;
    height: 24px;
    transform: rotate(45deg);
    background-color: white;
    border-bottom: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
}
/* h1, h2 {
    font-family: "Cormorant Garamond", "serif" !important; 
} */

.blocks-gallery-grid .blocks-gallery-item figcaption {
    background: none !important;
    color: grey !important;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a, .wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a, .wp-block-gallery.is-cropped .blocks-gallery-item img, .blocks-gallery-grid.is-cropped .blocks-gallery-image a, .blocks-gallery-grid.is-cropped .blocks-gallery-image img, .blocks-gallery-grid.is-cropped .blocks-gallery-item a, .blocks-gallery-grid.is-cropped .blocks-gallery-item img {
    background: #f5f5f5 !important;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a, .wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a, .wp-block-gallery.is-cropped .blocks-gallery-item img, .blocks-gallery-grid.is-cropped .blocks-gallery-image a, .blocks-gallery-grid.is-cropped .blocks-gallery-image img, .blocks-gallery-grid.is-cropped .blocks-gallery-item a, .blocks-gallery-grid.is-cropped .blocks-gallery-item img {
 
    object-fit: contain;
}

.wp-block-gallery, .blocks-gallery-grid {
    width: 100vw;
}


.fade {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
}



.blob {
    background: red;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    transform: scale(1);
    animation: pulse 1.5s infinite;
    display: inline-block;
    vertical-align: middle;
}

@keyframes pulse {
    0% {
        transform: scale(0.75);
        background: #fc374c;
    }

    70% {
        transform: scale(1);
        background: red;
    }

    100% {
        transform: scale(0.5);
        background: #fc374c;
    }
}

:root {
    --toastify-font-family: "Lato", "sans-serif";
    --toastify-text-color-light: #000000;
    --toastify-color-progress-light: linear-gradient(
    to right,
    #f5f5f5,
    #e0e0e0,
    #c7c7c7,
    #adadad,
    #939393,
    #797979
  );
}

.MuiInputAdornment-root {
    right: 48px; /* or adjust the value as needed */
  }
